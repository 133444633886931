import { IsArray, IsOptional, IsString } from "class-validator";
import { Transform } from "class-transformer";
import { Localized, transformLocalized } from "@namedicinu/internal-types";

import { IsAsset, IsLocalizedMarkdown, IsLocalizedString, IsLocalizedStringArray } from "../helpers/decorators";

import { WebEntry } from "./webEntry";

export class SiteInformation extends WebEntry {
  public get type() {
    return "siteInformation" as const;
  }

  @Transform(transformLocalized)
  @IsLocalizedString()
  siteName: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedString()
  siteDescription: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedStringArray()
  siteKeywords: Localized<string[]>;
  @Transform(transformLocalized)
  @IsLocalizedString()
  email: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedString()
  bannerSubtitle: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  bannerDescription: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  courseDescription: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  lectureDescription: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  productsDescription: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  registerDescription: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  testimonialsDescription: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  lecturersDescription: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  facultiesDescription: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  fieldsDescription: Localized<string>;
  @IsAsset("image")
  logo: string;
  @IsOptional()
  @IsString()
  fbPageId: string | null;
  @IsOptional()
  @IsString()
  fbAppId: string | null;
  @IsOptional()
  @IsString()
  facebook: string | null;
  @IsOptional()
  @IsString()
  instagram: string | null;
  @IsOptional()
  @IsString()
  linkedin: string | null;
  @IsOptional()
  @IsString()
  youtube: string | null;
  @IsOptional()
  @IsString()
  github: string | null;
  @IsOptional()
  @IsString()
  tiktok: string | null;
  @IsAsset("image")
  bannerImage: string;
  @IsArray()
  @IsAsset("document", { each: true })
  legalDocuments: string[];
  @IsArray()
  @IsAsset("document", { each: true })
  registerDocuments: string[];
}
