import { IsArray, IsOptional, IsString } from "class-validator";
import { Transform } from "class-transformer";
import type { Moment } from "moment";

import { transformMoment } from "../helpers/transforms";
import { IsMoment } from "../helpers/decorators";

export class QuizLink {
  @IsString()
  quizLinkId: string;
  @IsString()
  quizSelectionId: string;
  @IsString()
  seed: string;
  @Transform(transformMoment)
  @IsMoment()
  createdAt: Moment;
  @IsString()
  createdBy: string;
  @IsOptional()
  @IsArray()
  @IsString({ each: true })
  questions?: string[];
}
