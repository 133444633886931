import { IsString } from "class-validator";
import { Transform } from "class-transformer";
import { Localized, transformLocalized } from "@namedicinu/internal-types";

import { IsAsset, IsLocalizedMarkdown, IsLocalizedString } from "../helpers/decorators";

import { WebEntry } from "./webEntry";

export class Lecturer extends WebEntry {
  public get type() {
    return "lecturer" as const;
  }

  @IsString()
  name: string;
  @Transform(transformLocalized)
  @IsLocalizedString()
  designation: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  description: Localized<string>;
  @IsAsset("image")
  photo: string;
}
