import get from "lodash.get";
import { distance } from "fastest-levenshtein";

import { HaystackIndex, TroveOptions } from "./types";

export default class Trove<T> {
  haystack: T[];
  keys: string[];
  options: Required<TroveOptions>;
  haystackIndex: HaystackIndex<T> | undefined;

  constructor(haystack: T[], keys: string[], options?: TroveOptions) {
    this.haystack = haystack;
    this.keys = keys;
    this.options = Object.assign(
      {
        caseSensitive: false,
        normalizeLatinAccents: false,
        sort: false,
        keysWeightFalloff: 1.0,
        tokenize: false,
        searchTreashold: 0.6,
      },
      options,
    );
  }

  private normalize(value: string): string {
    if (!this.options.caseSensitive) {
      value = value.toLowerCase();
    }
    if (this.options.normalizeLatinAccents) {
      value = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    return value;
  }

  private prepareData() {
    return this.haystack.map((item) => {
      const fields = this.keys.map((key) => {
        const str = this.normalize(`${get(item, key)}`);
        return str;
      });

      return {
        fields,
        ref: item,
      };
    });
  }

  search(query: string): T[] {
    if (this.haystackIndex === undefined) {
      this.haystackIndex = this.prepareData();
    }

    let queryTokens: string | string[] = this.normalize(query);
    if (this.options.tokenize) {
      queryTokens = queryTokens.split(/\s+/);
    }

    let scores = this.getSearchScores(queryTokens, this.haystackIndex);

    const keep = scores.map((_, i) => scores[i]! >= this.options.searchTreashold);
    let results = this.haystack.filter((_, i) => keep[i]);

    if (this.options.sort) {
      scores = scores.filter((_, i) => keep[i]);
      const indices = new Array(results.length).fill(0).map((_, i) => i);
      indices.sort((a, b) => scores[b]! - scores[a]!);
      results = indices.map((i) => results[i]!);
    }

    return results;
  }

  private getSearchScores(query: string | string[], haystackIndex: HaystackIndex<T>): number[] {
    const scores = new Array<number>(haystackIndex.length).fill(0);
    if (typeof query == "string") {
      for (let i = 0; i < haystackIndex.length; i++) {
        const item = haystackIndex[i]!;
        let score = 0;
        for (const field of item.fields) {
          score += this.getSearchScore(field, query);
        }
        scores[i] = score;
      }
    } else if (Array.isArray(query)) {
      for (const queryToken of query) {
        const tokenScores = this.getSearchScores(queryToken, haystackIndex);
        for (let i = 0; i < haystackIndex.length; i++) {
          scores[i]! += tokenScores[i]!;
        }
      }
    }

    return scores;
  }

  /*
   * Returns a score between 0 and 1, where 1 is a perfect match.
   * The score is calculated using the Levenshtein distance between the value and the query.
   */
  private getSearchScore(value: string, query: string): number {
    const d = distance(value, query);
    const m = Math.max(value.length, query.length);
    return 1.0 / Math.exp(d / (m - d));
  }
}
