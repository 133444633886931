import { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"

const Unauthorized: FunctionComponent = () => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          <div className="card mt-5">
            <div className="card-body">
              <h5 className="card-title">
                <FormattedMessage
                  id="common.unauthorized.title"
                  defaultMessage="You are not authorized to view this page"
                />
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Unauthorized;
