import { createContext, ReactNode, FunctionComponent, useState, useEffect, useCallback } from "react";
import { Analytics } from "firebase/analytics";
import { Auth, onAuthStateChanged, User as AuthUser } from "firebase/auth";
import { FirebaseStorage } from "firebase/storage";
import { Moment} from "moment";
import { usePrefersColorScheme } from 'use-prefers-color-scheme';
import { Category, StudyGroup, User, UserNotification } from "@namedicinu/internal-types";

import { buildUserViews, resolveDateRange, userViewId } from "../helpers/utils";
import { DateRange, UserView } from "../types";
import { handleError } from "../helpers/error";
import ApiClient from '../clients/apiClient';
import AppConfigClient from "../clients/appConfigClient";
import LocalDatabase from "../clients/localDatabase";
import QuizClient from "../clients/quizClient";
import SchemaClient from "../clients/schemaClient";
import StatsClient from "../clients/statsClient";
import StorageClient from "../clients/storageClient";
import StudyMaterialClient from "../clients/studyMaterialClient";
import VideoClient from "../clients/videoClient";
import WebAssetTitleClient from "../clients/webAssetTitleClient";

export type AppParams = {
  auth: Auth;
  analytics: Analytics;
  storageSource: FirebaseStorage;
  storagePublic: FirebaseStorage;
  apiClient: ApiClient,
  storageClient: StorageClient,
  localDatabase: LocalDatabase,
  quizClient: QuizClient,
  videoClient: VideoClient,
  studyMaterialClient: StudyMaterialClient,
  schemaClient: SchemaClient,
  appConfigClient: AppConfigClient,
  statsClient: StatsClient,
  webAssetTitleClient: WebAssetTitleClient,
};

export type AppType = AppParams & {
  user: User|null|undefined;
  userPic: string|undefined;
  userViews: UserView[];
  userView: UserView;
  content: Category[];
  studyGroups: StudyGroup[];
  pickUserView: (view: UserView) => void;
  notifications: UserNotification[]|undefined;
  markNotificationAcknowledged: (notification: UserNotification) => void;
  markNotificationsAcknowledged: () => void;
  currentQuizSessionId: string|undefined;
  setCurrentQuizSessionId: (id: string|undefined) => void;
  currentVideoSessionId: string|undefined;
  setCurrentVideoSessionId: (id: string|undefined) => void;
  currentStudyMaterialSessionId: string|undefined;
  setCurrentStudyMaterialSessionId: (id: string|undefined) => void;
  storedColorScheme: "light"|"dark"|undefined;
  colorScheme: "light"|"dark";
  nextColorScheme: () => void;
  dateRange: DateRange,
  setDateRange: (dateRange: DateRange) => void,
  selectedDateRange: { from: Moment, to: Moment },
}

const AppContext = createContext<AppType|undefined>(undefined);

export const AppProvider: FunctionComponent<AppParams & { children: ReactNode }> = ({
  auth,
  analytics,
  storageSource,
  storagePublic,
  apiClient,
  storageClient,
  localDatabase,
  quizClient,
  videoClient,
  studyMaterialClient,
  schemaClient,
  appConfigClient,
  statsClient,
  webAssetTitleClient,
  children,
}) => {
  const [user, setUser] = useState<User|null|undefined>();
  const [userPic, setUserPic] = useState<string>();
  const [userViews, setUserViews] = useState<UserView[]>(buildUserViews(undefined, [], [], []));
  const [userView, setUserView] = useState<UserView>();
  const [content, setContent] = useState<Category[]>([]);
  const [studyGroups, setStudyGroups] = useState<StudyGroup[]>([]);
  const [notifications, setNotifications] = useState<UserNotification[]>();

  const [currentQuizSessionId, setCurrentQuizSessionId] = useState<string>();
  const [currentVideoSessionId, setCurrentVideoSessionId] = useState<string>();
  const [currentStudyMaterialSessionId, setCurrentStudyMaterialSessionId] = useState<string>();
  const [initialOffload, setInitialOffload] = useState(false);

  const [colorScheme, setColorScheme] = useState<"light"|"dark">();
  const prefersColorScheme = usePrefersColorScheme();
  const activeColorScheme = (
    colorScheme ? colorScheme :
    prefersColorScheme != "no-preference" ? prefersColorScheme :
    "light"
  );
  const [dateRange, setDateRange] = useState<DateRange>("last-month");

  const activeUserView = userView || userViews[0]!;
  const selectedDateRange = resolveDateRange(dateRange);

  useEffect(() => {
    const loadUser = async (authUser: AuthUser|null) => {
      apiClient.connectAuth(authUser);
      storageClient.connectAuth();
      if(authUser) {
        const init = await apiClient.getUserInitMe();
        setUser(init.user);
        setUserViews(buildUserViews(
          init.user, init.courseRegistrations, init.content, init.studyGroups
        ));
        setContent(init.content);
        setStudyGroups(init.studyGroups);
        setNotifications(init.notifications);
        const userPic = authUser.photoURL;
        setUserPic(userPic || undefined);
      } else {
        setUser(null);
        setUserViews(buildUserViews(undefined, [], [], []));
        setContent([]);
        setStudyGroups([]);
        setNotifications([]);
        setUserPic(undefined);
      }
    }
    onAuthStateChanged(auth, (authUser) => {
      loadUser(authUser).catch(handleError);
    });
  }, [auth]);

  // initial offload
  useEffect(() => {
    if(user && !initialOffload) {
      const timeout = setTimeout(() => {
        quizClient.offloadLogs(user, currentQuizSessionId).catch(handleError);
        videoClient.offloadLogs(user, currentVideoSessionId).catch(handleError);
        studyMaterialClient.offloadLogs(user, currentStudyMaterialSessionId).catch(handleError);
        statsClient.clearOldStatsEntries(user).catch(handleError);
        setInitialOffload(true);
      }, 1000 * 3);
      return () => clearTimeout(timeout);
    }
    return;
  }, [user, initialOffload, currentQuizSessionId, currentVideoSessionId]);

  // successive offloads just about every 8 minutes
  useEffect(() => {
    if(user) {
      const interval = setInterval(() => {
        quizClient.offloadLogs(user, currentQuizSessionId).catch(handleError);
        videoClient.offloadLogs(user, currentVideoSessionId).catch(handleError);
        studyMaterialClient.offloadLogs(user, currentStudyMaterialSessionId).catch(handleError);
      }, 1000 * 483);
      return () => clearInterval(interval);
    }
    return;
  }, [user, currentQuizSessionId, currentVideoSessionId]);

  useEffect(() => {
    const localColorScheme = localStorage.getItem("colorScheme")
    if (localColorScheme == "dark") setColorScheme("dark");
    if (localColorScheme == "light") setColorScheme("light");
  }, []);

  const pickColorScheme = useCallback((colorScheme: "light"|"dark"|undefined) => {
    setColorScheme(colorScheme);
    if (colorScheme) {
      localStorage.setItem("colorScheme", colorScheme);
    } else {
      localStorage.removeItem("colorScheme");
    }
  }, []);

  const nextColorScheme = useCallback(() => {
    if (prefersColorScheme == "dark") {
      if (colorScheme == undefined) pickColorScheme("light");
      if (colorScheme == "light") pickColorScheme("dark");
      if (colorScheme == "dark") pickColorScheme(undefined);
    } else {
      if (colorScheme == undefined) pickColorScheme("dark");
      if (colorScheme == "dark") pickColorScheme("light");
      if (colorScheme == "light") pickColorScheme(undefined);
    }
  }, [colorScheme, prefersColorScheme]);

  useEffect(() => {
    const userViewStr = localStorage.getItem("userView")
    if (userViewStr) {
      const view = userViews.find((view) => userViewId(view) === userViewStr);
      if(view) setUserView(view);
    }
  }, [userViews]);

  const pickUserView = useCallback((view: UserView) => {
    setUserView(view);
    localStorage.setItem("userView", userViewId(view));
  }, []);

  const markNotificationAcknowledged = useCallback((notification: UserNotification) => {
    if(notifications) {
      apiClient.acknowledgeNotification(notification.userNotificationId).then(() => {
        setNotifications(notifications.filter((n) => n.userNotificationId !== notification.userNotificationId));
      }).catch(handleError);
    }
  }, [apiClient, notifications, handleError]);


  const markNotificationsAcknowledged = useCallback(() => {
    apiClient.acknowledgeAllNotifications().then(() => setNotifications([])).catch(handleError);
  }, [apiClient, handleError]);

  return (
    <AppContext.Provider
      value={{
        auth,
        analytics,
        storageSource,
        storagePublic,
        apiClient,
        storageClient,
        localDatabase,
        quizClient,
        videoClient,
        studyMaterialClient,
        schemaClient,
        appConfigClient,
        statsClient,
        webAssetTitleClient,

        user,
        userPic,
        userViews,
        userView: activeUserView,
        content,
        studyGroups,
        pickUserView,
        notifications,
        markNotificationAcknowledged,
        markNotificationsAcknowledged,
        currentQuizSessionId,
        setCurrentQuizSessionId,
        currentVideoSessionId,
        setCurrentVideoSessionId,
        currentStudyMaterialSessionId,
        setCurrentStudyMaterialSessionId,
        storedColorScheme: colorScheme,
        colorScheme: activeColorScheme,
        nextColorScheme,
        dateRange,
        setDateRange,
        selectedDateRange,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
