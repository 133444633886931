import moment, { Moment } from "moment";
import * as yup from "yup";

export class MomentSchema<
  TType extends yup.Maybe<Moment> = Moment | undefined,
  TContext = yup.AnyObject,
  TDefault = undefined,
  TFlags extends yup.Flags = "",
> extends yup.Schema<TType, TContext, TDefault, TFlags> {
  constructor() {
    super({
      type: "moment",
      check(value: any): value is NonNullable<TType> {
        return moment.isMoment(value);
      },
    });

    this.withMutation(() => {
      this.transform((value) => {
        if (moment.isMoment(value)) {
          return value;
        }
        if (typeof value == "string") {
          return moment(value);
        }
        return undefined;
      });
    });
  }

  override required(message?: yup.Message<any>): MomentSchema<NonNullable<TType>, TContext, TDefault, TFlags> {
    return super.required(message) as MomentSchema<NonNullable<TType>, TContext, TDefault, TFlags>;
  }

  override optional(): MomentSchema<TType, TContext, TDefault, TFlags> {
    return super.optional() as MomentSchema<TType, TContext, TDefault, TFlags>;
  }

  override nullable(): MomentSchema<TType | null, TContext, TDefault, TFlags> {
    return super.nullable() as MomentSchema<TType | null, TContext, TDefault, TFlags>;
  }
}

export const yupMoment = new MomentSchema();
