import { FunctionComponent, ReactNode } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Alert, Button, Card, CardBody, CardHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { errorMessage, handleError } from "../helpers/error";

type AppErrorProps = {
  children: ReactNode;
}

const AppError: FunctionComponent<AppErrorProps> = ({ children }) => {

  const fallbackRender = (
    { error, resetErrorBoundary }: FallbackProps
  ) => {
    return (
      <Card>
        <CardHeader>
          <h5>
            <FormattedMessage id="common.error" defaultMessage="Error" />
          </h5>
        </CardHeader>
        <CardBody>
          <Alert color="danger" className="outline">
            {errorMessage(error)}
          </Alert>
          <Button color="danger" onClick={() => resetErrorBoundary()}>
            <FormattedMessage id="common.action.tryAgain" defaultMessage="Try again" />
          </Button>
        </CardBody>
      </Card>
    )
  }

  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onError={handleError}
    >
      {children}
    </ErrorBoundary>
  )
}

export default AppError;
