import * as yup from "yup";
import { type Moment } from "moment";

import { yupMoment } from "./moment";

export const yupRange = <T>(schema: yup.Schema<T>) =>
  yup.object({
    from: schema,
    to: schema,
  });

export const yupNumberRange = yupRange<number>(yup.number().required());

export const yupMomentRange = yupRange<Moment>(yupMoment.required());
