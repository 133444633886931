import { FunctionComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import {
  Col,
  Container,
  Nav,
  NavItem,
  Row,
} from "reactstrap";
import { NavLink, Outlet, useLocation } from "react-router-dom";

import Breadcrumb from "../../../components/Breadcrumb";
import AppError from "../../../components/AppError";
import DateRangeControl from "../../../components/DateRangeControl";

const UserWrapper: FunctionComponent = () => {
  const intl = useIntl();
  const location = useLocation();

  return (
    <>
      <Breadcrumb
        parent={intl.formatMessage({id: "page.index.title", defaultMessage: "Namedicinu"})}
        title={intl.formatMessage({id: "module.user.title", defaultMessage: "User"})}
      />
      <Container fluid={true}>
        <Row>
          <Col>
            <Nav pills className="mb-3 gap-3">
              <NavItem>
                <NavLink
                  to="/user"
                  className={({isActive}) => clsx("nav-link", {active: isActive})}
                  end={true}
                >
                  <FormattedMessage id="module.user.page.overview" defaultMessage="Overview" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/user/quiz"
                  className={({isActive}) => clsx("nav-link", {active: isActive})}
                >
                  <FormattedMessage id="module.user.page.quiz" defaultMessage="Quiz" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/user/lessons"
                  className={({isActive}) => clsx("nav-link", {active: isActive})}
                >
                  <FormattedMessage id="module.user.page.lessons" defaultMessage="Lessons" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/user/settings"
                  className={({isActive}) => clsx("nav-link", {active: isActive})}
                >
                  <FormattedMessage id="module.user.page.settings" defaultMessage="Settings" />
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          {(location.pathname.startsWith("/user/quiz") || location.pathname.startsWith("/user/lessons")) ? (
            <Col>
              <div className="d-flex justify-content-end">
                <DateRangeControl />
              </div>
            </Col>
          ) : null}
        </Row>
        <AppError>
          <Outlet />
        </AppError>
      </Container>
    </>
  );
}

export default UserWrapper;