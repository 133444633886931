import { createContext, ReactNode, FunctionComponent, useState, useEffect } from "react";
import { StudyGroup, User } from "@namedicinu/internal-types";

import { handleError } from "../../../helpers/error";
import useApp from "../../../hooks/useApp";

export type LectorScopeType = "students" | "study-group" | "global";

export type LectorType = {
  scope: LectorScopeType,
  setScope: (scope: LectorScopeType) => void,
  students?: User[]|undefined,
  selectedStudents?: User[]|undefined,
  studyGroups: StudyGroup[],
  studyGroup: StudyGroup|undefined,
  setStudyGroup: (studyGroup: StudyGroup|undefined) => void,
}

const LectorContext = createContext<LectorType|undefined>(undefined);

export const LectorProvider: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  const { user, apiClient, studyGroups } = useApp();
  const [scope, setScope] = useState<LectorScopeType>("students");
  const [studyGroup, setStudyGroup] = useState<StudyGroup|undefined>(studyGroups[0]);
  const [students, setStudents] = useState<User[]>();

  useEffect(() => {
    setStudents(undefined);
    if(user?.isLector()) {
      apiClient.getLectorStudents()
        .then(setStudents)
        .catch(handleError);
    }
  }, [user?.isLector(), apiClient]);

  let selectedStudents: User[]|undefined;
  if (scope === "global") {
    selectedStudents = undefined;
  } else if (scope == "students") {
    selectedStudents = students;
  } else if (scope == "study-group") {
    selectedStudents = students?.filter(student => (
      student.activeContentAccess.some(ca => ca.studyGroupId == studyGroup?.studyGroupId)
    ));
  }

  return (
    <LectorContext.Provider
      value={{
        scope,
        setScope,
        students,
        selectedStudents,
        studyGroups,
        studyGroup,
        setStudyGroup,
      }}
    >
      {children}
    </LectorContext.Provider>
  );
};

export default LectorContext;
