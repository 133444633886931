import type { ReactNode } from "react";
import type { RouteObject } from "react-router-dom";
import type { Moment } from "moment";
import type { CourseRegistration, Localized, RoleType, StudyGroup } from "@namedicinu/internal-types";

export type Module = {
  route: RouteObject;
  allowed: (userView: UserView) => boolean;
  name: ReactNode;
  icon?: ReactNode;
};

export type UserContentTree = {
  categories: Array<{
    categoryId: string;
    title: Localized<string>;
    topics: Array<{
      topicId: string;
      title: Localized<string>;
    }>;
  }>;
};

export type UserContentStudyGroups = {
  studyGroups: StudyGroup[];
};

export type UserModulesContent = {
  quiz: UserContentTree | false;
  lector: boolean;
  video: UserContentStudyGroups | false;
  videoManager: boolean;
  studyMaterial: UserContentStudyGroups | false;
  admin: boolean;
};

export type UserView = {
  role?: RoleType;
  courseRegistration?: CourseRegistration;
  modules: UserModulesContent;
};

export type QuizModeConfig = {
  id: string;
  topicIds?: Array<string>;
  showAnswers?: boolean;
  shuffleAnswers?: boolean;
  allQuestionsAtOnce?: boolean;
  allowQuestionReminders?: boolean;
  selectQuestionsRange?: {
    from: number;
    to: number;
  };
  selectQuestionAreas?: null | string[];
  questionsPerQuiz?: number;
  questionsPreddefined?: boolean;
  answersToChooseFrom?: number;
  timer?: null | number;
  customize: QuizCustomize;
};

export type CategoryConfig = {
  quizSelection?: {
    preddefinedShuffleLevel?: number;
  };
  modes: Array<QuizModeConfig>;
};

export type QuizCustomize = {
  topicIds?: string[];
  showAnswers?: true;
  shuffleAnswers?: true;
  allQuestionsAtOnce?: true;
  allowQuestionReminders?: true;
  selectQuestionsRange?: {
    step: number;
  };
  selectQuestionAreas?: true;
  questionsPerQuiz?: number[];
  questionsPreddefined?: true;
  answersToChooseFrom?: number[];
  timer?: (null | number)[];
};

export type QuizReleaseConfig = {
  quizReleaseId: string;
  categoryId: string;
  config: CategoryConfig;
  topics: Array<{
    topicId: string;
    questionSetConfigs: QuestionSetConfig[];
    questions: Question[];
  }>;
};

export type QuizInteraction = "singleChoice" | "multipleChoice" | "upToTwoChoice" | "atLeastOneChoice";

export type QuestionSetConfig = {
  totalQuestions: number;
  interaction: QuizInteraction;
  quizSelection: {
    requiresAtLeastOneCorrect?: boolean;
    perserveLastAnswer?: boolean;
    perserveLastTwoAnswers?: boolean;
  };
  scoring: {
    scope: "answers" | "question";
    startingScore: number;
    selectedCorrect: number;
    selectedIncorrect: number;
    unselectedCorrect: number;
    unselectedIncorrect: number;
    levels?: number[];
  };
  areas: Array<{
    title: string;
    questions: Array<number>;
  }>;
  assessment: Array<{
    amount: number;
    from: Array<number>;
  }>;
};

export type Answer = {
  option: string;
  message: string;
  correct: boolean;
  image: string | null;
};

export type Question = {
  number: number;
  quid: string;
  question: string;
  answers: Array<Answer>;
  image: string | null;
  questionSetIndex: number;
};

export type QuestionWithQuestionSet = Question & { questionSet: QuestionSetConfig };

export type Course = {
  title: string;
  description: string;
  isVirtual: boolean;
  priceMin: number;
  priceMax: number;
  registerStart: string | null;
  registerEnd: string | null;
  courseStart: string | null;
  courseEnd: string | null;
};

export const preddefinedDateRanges = ["last-week", "last-month", "last-year"] as const;
export type PreddefinedDateRange = (typeof preddefinedDateRanges)[number];
export type DateRange =
  | PreddefinedDateRange
  | {
      from: Moment;
      to: Moment;
    };
