import { validationMetadatasToSchemas } from "class-validator-jsonschema";
import type { ClassConstructor } from "class-transformer";
import { SchemaObject /*, ReferenceObject, isReferenceObject*/ } from "openapi3-ts";
import { JsonObject, JsonType, additionalConverters as internalConverters } from "@namedicinu/internal-types";
import { additionalConverters as webConverters } from "@namedicinu/web-types";

export default class SchemaClient {
  _schemas: Record<string, SchemaObject> | undefined = undefined;

  public get schemas(): Record<string, SchemaObject> {
    if (!this._schemas) {
      this._schemas = validationMetadatasToSchemas({
        additionalConverters: {
          ...internalConverters,
          ...webConverters,
        },
      });
    }
    return this._schemas;
  }

  getSchema(name: string, unrollRefs?: boolean): SchemaObject;
  getSchema(ctor: ClassConstructor<any>, unrollRefs?: boolean): SchemaObject;
  getSchema(name: string | ClassConstructor<any>, unrollRefs?: boolean): SchemaObject;
  getSchema(name: string | ClassConstructor<any>, unrollRefs: boolean = true): SchemaObject {
    if (typeof name === "function") {
      name = name.name;
    }
    let schema = this.schemas[name];
    if (schema === undefined) {
      throw new Error(`Schema ${name} not found`);
    }
    if (unrollRefs) {
      // schema = this.unrollRefs(schema);
      schema = this.unrollRefs(name, schema) as SchemaObject;
    }

    if (schema.type === "object") {
      if (schema.properties && schema.properties["user"]) {
        delete schema.properties["user"];
      }
      if (schema.required) {
        schema.required = schema.required.filter((r) => r !== "user");
      }
    }

    return schema;
  }

  // TODO temporary solutions
  unrollRefs(name: string, schema: any): any {
    if (name === "Category") {
      schema.properties.topics = { type: "array", items: this.getSchema("Topic") };
    }
    if (name === "Email") {
      schema.properties.message = this.getSchema("EmailMessage");
      schema.properties.attachments = { type: "array", items: this.getSchema("EmailAttachment") };
      schema.properties.delivery = this.getSchema("EmailDelivery");
      schema.properties.schedule = this.getSchema("EmailSchedule");
    }
    return schema;
  }

  // TODO $ref objects are not present in the schemas returned by class-validator-jsonschema for some reason, I have no
  // idea what's going on there.
  //
  // unrollRefs(schema: SchemaObject|ReferenceObject): SchemaObject {
  //   if(isReferenceObject(schema)) {
  //     const ref = schema.$ref;
  //     if(!ref) {
  //       throw new Error('Invalid reference object');
  //     }
  //     const name = ref.split('/').pop();
  //     if(!name) {
  //       throw new Error('Invalid reference object');
  //     }
  //     return this.unrollRefs(this.getSchema(name));
  //   } else {
  //     if(schema.properties) {
  //       for(const key in schema.properties) {
  //         schema.properties[key] = this.unrollRefs(schema.properties[key]!);
  //       }
  //     }
  //     if(schema.items) {
  //       schema.items = this.unrollRefs(schema.items);
  //     }
  //     return schema;
  //   }
  // }

  getDefaultData(name: string | ClassConstructor<any>, partialDefaults?: JsonObject): JsonObject {
    const schema = this.getSchema(name, true);
    return this.buildDefaultData(schema, partialDefaults || {});
  }

  buildDefaultData(schema: SchemaObject, partialDefaults: JsonObject): JsonObject {
    const data: JsonObject = {};
    for (const key in schema.properties) {
      const prop = schema.properties[key] as SchemaObject;
      if (partialDefaults[key] !== undefined && partialDefaults[key] !== null) {
        data[key] = partialDefaults[key]!;
      } else if (prop.default !== undefined) {
        data[key] = prop.default;
      } else if (prop.type === "object") {
        data[key] = this.buildDefaultData(prop, (partialDefaults[key] || {}) as JsonObject);
      } else if (prop.type === "array") {
        data[key] = [];
      } else if (prop.type === "string") {
        data[key] = "";
      } else if (prop.type === "number") {
        data[key] = 0;
      } else if (prop.type === "boolean") {
        data[key] = false;
      } else {
        data[key] = null;
      }
    }
    return data;
  }

  // Assuming that data structure matches schema, behavior is not defined otherwise
  dropEmptyNonRequiredFields<T extends JsonType>(data: T, schema: SchemaObject): T {
    if (schema.type == "object") {
      const dataObject = data as JsonObject;
      const result: JsonObject = {};
      for (const key in schema.properties) {
        const prop = schema.properties[key] as SchemaObject;
        if (prop.required || dataObject[key]) {
          result[key] = this.dropEmptyNonRequiredFields(dataObject[key]!, prop);
        }
      }
      return result as T;
    } else if (schema.type == "array") {
      const dataArray = data as JsonType[];
      return dataArray.map((d) => this.dropEmptyNonRequiredFields(d, schema.items as SchemaObject)) as T;
    } else {
      return data;
    }
  }
}
