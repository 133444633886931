import { FunctionComponent } from "react";
import { Helmet } from "react-helmet";

import useLocale from "../hooks/useLocale";
import useApp from "../hooks/useApp";

const PageHead: FunctionComponent<{
  children: string;
}> = ({
  children: title,
}) => {
  const { colorScheme } = useApp();
  const locale = useLocale();

  return (
    <Helmet>
      <title>
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
        {title} | {locale == "en" ? "PremedicalCourse" : "NaMedicínu"}
      </title>
      <link rel="icon" type="image/png" href={colorScheme == "dark" ? (
        "/images/favicon-dark.png"
      ) : (
        "/images/favicon.png"
      )} />
    </Helmet>
  );
}

export default PageHead;
