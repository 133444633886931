import * as yup from "yup";
import { Allow } from "class-validator";

import { IsYup } from "../helpers/decorators";
import { yupRecord } from "../schemas/record";

import { User } from "./user";

export type UserAffinityContextType = "quiz";

export class UserAffinity {
  @Allow()
  user: User;

  @IsYup(yupRecord(yup.string().required(), yup.number().required().min(-1).max(1)))
  affinity: Record<string, number>;
}
