import axios from "axios";

const extractMainHash = (html: string) => {
  const m = html.match(/src="\/assets\/index-(.*?)\.js"/);
  return m ? m[1] : undefined;
};

export function enableReloadChecker() {
  const originalHash = extractMainHash(document.head.innerHTML);
  let lastActivity = Date.now();

  setInterval(() => {
    if (lastActivity < Date.now() - 300000) {
      // at least 5 minutes ago
      void axios
        .get<Document>("/index.html", { responseType: "document" })
        .then((res) => {
          const newHash = extractMainHash(res.data.head.innerHTML);

          if (originalHash && newHash && newHash !== originalHash) {
            console.info(`New app release found: ${originalHash} (${import.meta.env.APP_RELEASE}) -> ${newHash}`);
            window.location.reload();
          }
        })
        .catch(console.warn); // Not critical
    }
  }, 10800000); // 3 hours

  const resetActivity = () => {
    lastActivity = Date.now();
  };

  document.addEventListener("mousedown", resetActivity);
  document.addEventListener("keydown", resetActivity);
}
