import { Transform } from "class-transformer";
import { Localized, transformLocalized } from "@namedicinu/internal-types";

import { IsLocalizedMarkdown, IsLocalizedString } from "../helpers/decorators";

import { WebEntry } from "./webEntry";

export class FAQ extends WebEntry {
  public get type() {
    return "faq" as const;
  }

  @Transform(transformLocalized)
  @IsLocalizedString()
  question: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  answer: Localized<string>;
}
