import { IsInt, IsOptional, IsString } from "class-validator";
import { Transform } from "class-transformer";
import { Localized, transformLocalized } from "@namedicinu/internal-types";

import { IsLocalizedMarkdown, IsReference } from "../helpers/decorators";

import { WebEntry } from "./webEntry";

export class Testimonial extends WebEntry {
  public get type() {
    return "testimonial" as const;
  }

  @IsString()
  name: string;
  @IsReference("faculty")
  faculty: string;
  @IsInt()
  year: number;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  description: Localized<string>;
  @IsOptional()
  @IsString()
  instagram: string | null;
  @IsOptional()
  @IsString()
  facebook: string | null;
}
