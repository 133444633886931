import { lazy } from "react";
import { FormattedMessage } from "react-intl";
import { Video } from "react-feather";

import { Module } from "../../types";

import VideoManagerWrapper from "./components/VideoManagerWrapper";

const VideoManagerListPage = lazy(() => import("./pages/VideoManagerListPage"));
const VideoManagerEditPage = lazy(() => import("./pages/VideoManagerEditPage"));

export default {
  route: {
    path: "/video-manager",
    element: <VideoManagerWrapper />,
    children: [
      {
        path: "/video-manager",
        index: true,
        element: <VideoManagerListPage />,
      },
      {
        path: "/video-manager/:videoId",
        element: <VideoManagerEditPage />,
      },
    ]
  },
  allowed: (userView) => userView.modules.videoManager,
  name: <FormattedMessage id="module.video-manager.name" defaultMessage="Video manager" />,
  icon: <Video />,
} satisfies Module;
