import { IsArray, IsOptional } from "class-validator";
import { Localized, transformLocalized } from "@namedicinu/internal-types";
import { Transform } from "class-transformer";

import { IsLocalizedString, IsReference } from "../helpers/decorators";

import { WebEntry } from "./webEntry";

export class QuizQuestion extends WebEntry {
  public get type() {
    return "quizQuestion" as const;
  }

  @Transform(transformLocalized)
  @IsLocalizedString()
  question: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedString()
  answerA: Localized<string>;
  @IsArray()
  @IsReference("faculty", { each: true })
  resultA: Array<string>;
  @Transform(transformLocalized)
  @IsLocalizedString()
  answerB: Localized<string>;
  @IsArray()
  @IsReference("faculty", { each: true })
  resultB: Array<string>;
  @IsOptional()
  @Transform(transformLocalized)
  @IsLocalizedString()
  answerC?: Localized<string>;
  @IsOptional()
  @IsArray()
  @IsReference("faculty", { each: true })
  resultC?: Array<string>;
}
