import { FunctionComponent } from "react";
import { FormattedDate } from "react-intl";

import useApp from "../hooks/useApp";

import LocalizedTitle from "./LocalizedTitle";


const DateRangeOutlet: FunctionComponent = () => {
  const { dateRange } = useApp();

  return (
    typeof dateRange == "string" ? (
      <LocalizedTitle prefix="common.analytics.date-range" id={dateRange} />
    ) : (
      <>
        <FormattedDate value={dateRange.from.toDate()} />
        {" - "}
        <FormattedDate value={dateRange.to.toDate()} />
      </>
    )
  )
}

export default DateRangeOutlet;
