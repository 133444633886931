import { openDB, IDBPDatabase, IDBPObjectStore, IDBPTransaction, StoreNames } from "idb";
import moment from "moment";
import { JsonObject, JsonType, UserAffinityContextType, guid } from "@namedicinu/internal-types";

import { QuizReleaseConfig } from "../types";
import { sleep } from "../helpers/utils";

import {
  QuizLogEntryJournal,
  QuizSessionJournal,
  UserAffinityCache,
  VideoLogEntryJournal,
  VideoSessionJournal,
  StudyMaterialLogEntryJournal,
  StudyMaterialSessionJournal,
  LocalStats,
} from "./types";

type DBType = {
  quizReleaseCache: QuizReleaseConfig;
  userAffinityCache: UserAffinityCache;
  quizSessionJournal: QuizSessionJournal;
  localStats: LocalStats;
};

export default class LocalDatabase {
  openDbPromise: Promise<IDBPDatabase<DBType>> | undefined = undefined;

  async getDb(): Promise<IDBPDatabase<DBType>> {
    if (!this.openDbPromise) {
      this.openDbPromise = this.openDb();
    }
    return this.openDbPromise;
  }

  async openDb(): Promise<IDBPDatabase<DBType>> {
    return openDB("namedicinu", 9, {
      upgrade(db, oldVersion, newVersion) {
        if (!newVersion || oldVersion < 8) {
          // reset local database
          for (const store of db.objectStoreNames) {
            db.deleteObjectStore(store);
          }
        }

        if (!!oldVersion || oldVersion < 8) {
          // setup new database
          db.createObjectStore("quizReleaseCache", {
            keyPath: "categoryId",
          });

          const quizSessionJournal = db.createObjectStore("quizSessionJournal", {
            keyPath: "localSessionId",
          });
          quizSessionJournal.createIndex("userId", "userId");

          const quizLogJournal = db.createObjectStore("quizLogJournal", {
            autoIncrement: true,
          });
          quizLogJournal.createIndex("localSessionId", "localSessionId");

          db.createObjectStore("userAffinityCache", {
            autoIncrement: false,
          });

          const videoSessionJournal = db.createObjectStore("videoSessionJournal", {
            keyPath: "localSessionId",
          });
          videoSessionJournal.createIndex("userId", "userId");
          const videoLogJournal = db.createObjectStore("videoLogJournal", {
            autoIncrement: true,
          });
          videoLogJournal.createIndex("localSessionId", "localSessionId");

          const localStats = db.createObjectStore("localStats", {
            keyPath: "localStatsId",
          });
          localStats.createIndex("modelId", "modelId");
          localStats.createIndex("userId", "userId");
        }

        if (!oldVersion || oldVersion < 9) {
          const studyMaterialSessionJournal = db.createObjectStore("studyMaterialSessionJournal", {
            keyPath: "localSessionId",
          });
          studyMaterialSessionJournal.createIndex("userId", "userId");
          const studyMaterialLogJournal = db.createObjectStore("studyMaterialLogJournal", {
            autoIncrement: true,
          });
          studyMaterialLogJournal.createIndex("localSessionId", "localSessionId");
        }
      },
      terminated: () => {
        this.openDbPromise = undefined;
      },
    });
  }

  /**
   * Unilities
   **/

  async getTransaction<Name extends StoreNames<DBType>, Mode extends IDBTransactionMode = "readonly">(
    storeName: Name,
    mode?: Mode,
    options?: IDBTransactionOptions,
  ): Promise<IDBPTransaction<DBType, [Name], Mode>> {
    const db = await this.getDb();
    let retry = 0;
    let error: any;
    while (retry < 3) {
      try {
        const tt = db.transaction(storeName, mode, options);
        return tt;
      } catch (e: any) {
        retry++;
        error = e;
        await sleep(100 * retry);
      }
    }
    throw error;
  }

  async getTransactionMulti<Names extends ArrayLike<StoreNames<DBType>>, Mode extends IDBTransactionMode = "readonly">(
    storeNames: Names,
    mode?: Mode,
    options?: IDBTransactionOptions,
  ): Promise<IDBPTransaction<DBType, Names, Mode>> {
    const db = await this.getDb();
    let retry = 0;
    let error: any;
    while (retry < 3) {
      try {
        const tt = db.transaction(storeNames, mode, options);
        return tt;
      } catch (e: any) {
        retry++;
        error = e;
        await sleep(100 * retry);
      }
    }
    throw error;
  }

  async getQuizReleaseCache<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["quizReleaseCache"], Mode>;
    store: IDBPObjectStore<DBType, ["quizReleaseCache"], "quizReleaseCache", Mode>;
  }> {
    const tt = await this.getTransaction("quizReleaseCache", mode);
    return { tt, store: tt.store };
  }

  async getUserAffinityCache<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["userAffinityCache"], Mode>;
    store: IDBPObjectStore<DBType, ["userAffinityCache"], "userAffinityCache", Mode>;
  }> {
    const tt = await this.getTransaction("userAffinityCache", mode);
    return { tt, store: tt.store };
  }

  async getQuizSessionJournal<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["quizSessionJournal"], Mode>;
    store: IDBPObjectStore<DBType, ["quizSessionJournal"], "quizSessionJournal", Mode>;
  }> {
    const tt = await this.getTransaction("quizSessionJournal", mode);
    return { tt, store: tt.store };
  }

  async getQuizLogJournal<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["quizLogJournal"], Mode>;
    store: IDBPObjectStore<DBType, ["quizLogJournal"], "quizLogJournal", Mode>;
  }> {
    const tt = await this.getTransaction("quizLogJournal", mode);
    return { tt, store: tt.store };
  }

  async getQuizSessionAndLogJournal<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["quizSessionJournal", "quizLogJournal"], Mode>;
    sessionStore: IDBPObjectStore<DBType, ["quizSessionJournal", "quizLogJournal"], "quizSessionJournal", Mode>;
    logStore: IDBPObjectStore<DBType, ["quizSessionJournal", "quizLogJournal"], "quizLogJournal", Mode>;
  }> {
    const tt = await this.getTransactionMulti(["quizSessionJournal", "quizLogJournal"] as const, mode);
    return {
      tt,
      sessionStore: tt.objectStore("quizSessionJournal"),
      logStore: tt.objectStore("quizLogJournal"),
    };
  }

  async getVideoSessionJournal<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["videoSessionJournal"], Mode>;
    store: IDBPObjectStore<DBType, ["videoSessionJournal"], "videoSessionJournal", Mode>;
  }> {
    const tt = await this.getTransaction("videoSessionJournal", mode);
    return { tt, store: tt.store };
  }

  async getVideoLogJournal<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["videoLogJournal"], Mode>;
    store: IDBPObjectStore<DBType, ["videoLogJournal"], "videoLogJournal", Mode>;
  }> {
    const tt = await this.getTransaction("videoLogJournal", mode);
    return { tt, store: tt.store };
  }

  async getVideoSessionAndLogJournal<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["videoSessionJournal", "videoLogJournal"], Mode>;
    sessionStore: IDBPObjectStore<DBType, ["videoSessionJournal", "videoLogJournal"], "videoSessionJournal", Mode>;
    logStore: IDBPObjectStore<DBType, ["videoSessionJournal", "videoLogJournal"], "videoLogJournal", Mode>;
  }> {
    const tt = await this.getTransactionMulti(["videoSessionJournal", "videoLogJournal"] as const, mode);
    return {
      tt,
      sessionStore: tt.objectStore("videoSessionJournal"),
      logStore: tt.objectStore("videoLogJournal"),
    };
  }

  async getStudyMaterialSessionJournal<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["studyMaterialSessionJournal"], Mode>;
    store: IDBPObjectStore<DBType, ["studyMaterialSessionJournal"], "studyMaterialSessionJournal", Mode>;
  }> {
    const tt = await this.getTransaction("studyMaterialSessionJournal", mode);
    return { tt, store: tt.store };
  }

  async getStudyMaterialLogJournal<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["studyMaterialLogJournal"], Mode>;
    store: IDBPObjectStore<DBType, ["studyMaterialLogJournal"], "studyMaterialLogJournal", Mode>;
  }> {
    const tt = await this.getTransaction("studyMaterialLogJournal", mode);
    return { tt, store: tt.store };
  }

  async getStudyMaterialSessionAndLogJournal<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["studyMaterialSessionJournal", "studyMaterialLogJournal"], Mode>;
    sessionStore: IDBPObjectStore<
      DBType,
      ["studyMaterialSessionJournal", "studyMaterialLogJournal"],
      "studyMaterialSessionJournal",
      Mode
    >;
    logStore: IDBPObjectStore<
      DBType,
      ["studyMaterialSessionJournal", "studyMaterialLogJournal"],
      "studyMaterialLogJournal",
      Mode
    >;
  }> {
    const tt = await this.getTransactionMulti(
      ["studyMaterialSessionJournal", "studyMaterialLogJournal"] as const,
      mode,
    );
    return {
      tt,
      sessionStore: tt.objectStore("studyMaterialSessionJournal"),
      logStore: tt.objectStore("studyMaterialLogJournal"),
    };
  }

  async getLocalStats<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["localStats"], Mode>;
    store: IDBPObjectStore<DBType, ["localStats"], "localStats", Mode>;
  }> {
    const tt = await this.getTransaction("localStats", mode);
    return { tt, store: tt.store };
  }

  /**
   * Quiz release
   **/

  async storeQuizRelease(releaseConfig: QuizReleaseConfig): Promise<void> {
    const { tt, store } = await this.getQuizReleaseCache("readwrite");
    await store.put(releaseConfig);
    await tt.done;
  }

  async getQuizReleaseOptional(categoryId: string): Promise<QuizReleaseConfig | undefined> {
    const { tt, store } = await this.getQuizReleaseCache();
    const quizRelease = await store.get(categoryId);
    await tt.done;
    return quizRelease;
  }

  /**
   * Quiz user question model
   **/

  async storeUserAffinity(userAffinityCache: UserAffinityCache): Promise<void> {
    const { tt, store } = await this.getUserAffinityCache("readwrite");
    await store.put(userAffinityCache, `${userAffinityCache.userId}@${userAffinityCache.type}`);
    await tt.done;
  }

  async getUserAffinity(userId: string, type: UserAffinityContextType): Promise<UserAffinityCache | undefined> {
    const { tt, store } = await this.getUserAffinityCache();
    const userAffinityCache = await store.get(`${userId}@${type}`);
    await tt.done;
    return userAffinityCache;
  }

  /**
   * Quiz session & entries journal
   */

  async storeQuizSession(session: QuizSessionJournal): Promise<void> {
    const { tt, store } = await this.getQuizSessionJournal("readwrite");
    await store.put(session);
    await tt.done;
  }

  async getQuizSessions(userId: string): Promise<Array<QuizSessionJournal>> {
    const { tt, store } = await this.getQuizSessionJournal();
    const quizSessions = await store.index("userId").getAll(userId);
    await tt.done;
    return quizSessions;
  }

  async getQuizSession(userId: string, localSessionId: string): Promise<QuizSessionJournal> {
    const sessions = await this.getQuizSessions(userId);
    const session = sessions.find((session) => session.localSessionId === localSessionId);
    if (!session) throw new Error(`Session not found: ${localSessionId}`);
    return session;
  }

  async storeQuizLogEntry(logEntry: QuizLogEntryJournal): Promise<void> {
    const { tt, store } = await this.getQuizLogJournal("readwrite");
    await store.put(logEntry);
    await tt.done;
  }

  async storeQuizLogEntries(logEntries: Array<QuizLogEntryJournal>): Promise<void> {
    const { tt, store } = await this.getQuizLogJournal("readwrite");
    for (const logEntry of logEntries) {
      await store.put(logEntry);
    }
    await tt.done;
  }

  async getQuizLogEntries(localSessionId: string): Promise<Array<QuizLogEntryJournal>> {
    const { tt, store } = await this.getQuizLogJournal();
    const quizLogEntries = await store.index("localSessionId").getAll(localSessionId);
    await tt.done;
    return quizLogEntries;
  }

  async clearQuizLog(localSessionId: string): Promise<void> {
    const { tt, sessionStore, logStore } = await this.getQuizSessionAndLogJournal("readwrite");
    await sessionStore.delete(localSessionId);
    const logKeys = await logStore.index("localSessionId").getAllKeys(IDBKeyRange.only(localSessionId));
    for (const key of logKeys) {
      await logStore.delete(key);
    }
    await tt.done;
  }

  /**
   * Video session & entries journal
   */

  async storeVideoSession(session: VideoSessionJournal): Promise<void> {
    const { tt, store } = await this.getVideoSessionJournal("readwrite");
    await store.put(session);
    await tt.done;
  }

  async getVideoSessions(userId: string): Promise<Array<VideoSessionJournal>> {
    const { tt, store } = await this.getVideoSessionJournal();
    const videoSessions = await store.index("userId").getAll(userId);
    await tt.done;
    return videoSessions;
  }

  async storeVideoLogEntry(logEntry: VideoLogEntryJournal): Promise<void> {
    const { tt, store } = await this.getVideoLogJournal("readwrite");
    await store.put(logEntry);
    await tt.done;
  }

  async storeVideoLogEntries(logEntries: Array<VideoLogEntryJournal>): Promise<void> {
    const { tt, store } = await this.getVideoLogJournal("readwrite");
    for (const logEntry of logEntries) {
      await store.put(logEntry);
    }
    await tt.done;
  }

  async getVideoLogEntries(localSessionId: string): Promise<Array<VideoLogEntryJournal>> {
    const { tt, store } = await this.getVideoLogJournal();
    const videoLogEntries = await store.index("localSessionId").getAll(localSessionId);
    await tt.done;
    return videoLogEntries;
  }

  async clearVideoLog(localSessionId: string, keepSession?: boolean): Promise<void> {
    const { tt, sessionStore, logStore } = await this.getVideoSessionAndLogJournal("readwrite");
    if (!keepSession) {
      await sessionStore.delete(localSessionId);
    }
    const logKeys = await logStore.index("localSessionId").getAllKeys(IDBKeyRange.only(localSessionId));
    for (const key of logKeys) {
      await logStore.delete(key);
    }
    await tt.done;
  }

  /**
   * Study material session & entries journal
   */

  async storeStudyMaterialSession(session: StudyMaterialSessionJournal): Promise<void> {
    const { tt, store } = await this.getStudyMaterialSessionJournal("readwrite");
    await store.put(session);
    await tt.done;
  }

  async getStudyMaterialSessions(userId: string): Promise<Array<StudyMaterialSessionJournal>> {
    const { tt, store } = await this.getStudyMaterialSessionJournal();
    const studyMaterialSessions = await store.index("userId").getAll(userId);
    await tt.done;
    return studyMaterialSessions;
  }

  async storeStudyMaterialLogEntry(logEntry: StudyMaterialLogEntryJournal): Promise<void> {
    const { tt, store } = await this.getStudyMaterialLogJournal("readwrite");
    await store.put(logEntry);
    await tt.done;
  }

  async storeStudyMaterialLogEntries(logEntries: Array<StudyMaterialLogEntryJournal>): Promise<void> {
    const { tt, store } = await this.getStudyMaterialLogJournal("readwrite");
    for (const logEntry of logEntries) {
      await store.put(logEntry);
    }
    await tt.done;
  }

  async getStudyMaterialLogEntries(localSessionId: string): Promise<Array<StudyMaterialLogEntryJournal>> {
    const { tt, store } = await this.getStudyMaterialLogJournal();
    const studyMaterialLogEntries = await store.index("localSessionId").getAll(localSessionId);
    await tt.done;
    return studyMaterialLogEntries;
  }

  async clearStudyMaterialLog(localSessionId: string, keepSession?: boolean): Promise<void> {
    const { tt, sessionStore, logStore } = await this.getStudyMaterialSessionAndLogJournal("readwrite");
    if (!keepSession) {
      await sessionStore.delete(localSessionId);
    }
    const logKeys = await logStore.index("localSessionId").getAllKeys(IDBKeyRange.only(localSessionId));
    for (const key of logKeys) {
      await logStore.delete(key);
    }
    await tt.done;
  }

  /**
   * Stats
   */
  async storeStatsEntries(userId: string, modelId: string, entries: JsonObject[]): Promise<void> {
    const { tt, store } = await this.getLocalStats("readwrite");
    for (const entry of entries) {
      await store.put({
        userId,
        localStatsId: guid(),
        modelId,
        timestamp: moment().utc().unix(),
        entry,
      });
    }
    await tt.done;
  }

  async getStatsEntries(userId: string, modelId: string): Promise<Array<Record<string, JsonType>>> {
    const todayTimestamp = moment().utc().startOf("day").unix();
    const { tt, store } = await this.getLocalStats();
    const rows = await store.index("modelId").getAll(modelId);
    const statsEntries = rows
      .filter((entry) => entry.timestamp >= todayTimestamp && entry.userId == userId)
      .map((row) => row.entry);
    await tt.done;
    return statsEntries;
  }

  async clearOldStatsEntries(userId: string): Promise<void> {
    const todayTimestamp = moment().utc().startOf("day").unix();
    const { tt, store } = await this.getLocalStats("readwrite");
    const rows = await store.index("userId").getAll(userId);
    for (const row of rows) {
      if (row.entry.timestamp < todayTimestamp) {
        await store.delete(row.localStatsId);
      }
    }
    await tt.done;
  }
}
