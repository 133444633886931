import { useContext } from "react";

import AppContext from "../contexts/AppContext";

const useApp = () => {
  const app = useContext(AppContext);
  if (!app) throw new Error("AppProvider missing");
  return app;
};

export default useApp;
