import { IsOptional } from "class-validator";
import { Transform } from "class-transformer";
import { Localized, transformLocalized } from "@namedicinu/internal-types";

import { IsAsset, IsLocalizedMarkdown, IsLocalizedString, IsLocalizedStringArray } from "../helpers/decorators";

import { WebEntry } from "./webEntry";

export class Blog extends WebEntry {
  public get type() {
    return "blog" as const;
  }

  @Transform(transformLocalized)
  @IsLocalizedString()
  title: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedString()
  abstract: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  content: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedStringArray()
  tags: Localized<string[]>;
  @IsOptional()
  @IsAsset("image")
  image: string | null;
}
