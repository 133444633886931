import { IsEnum, IsOptional, IsString } from "class-validator";
import { locales, type LocaleType } from "@namedicinu/internal-types";

export class WebDeployment {
  @IsEnum(locales)
  locale: LocaleType;
  @IsString()
  domain: string;
  @IsString()
  url: string;
  @IsString()
  testUrl: string;
  @IsOptional()
  @IsString()
  deployTaskId?: string;
  @IsOptional()
  @IsString()
  deployTestTaskId?: string;
}
