import { IsInt, IsOptional } from "class-validator";
import { Transform } from "class-transformer";
import { Localized, transformLocalized } from "@namedicinu/internal-types";

import { IsLocalizedMarkdown, IsLocalizedString } from "../helpers/decorators";

import { WebEntry } from "./webEntry";

export class PaymentFrequency extends WebEntry {
  public get type() {
    return "paymentFrequency" as const;
  }

  @Transform(transformLocalized)
  @IsLocalizedString()
  title: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  message: Localized<string>;
  @IsOptional()
  @IsInt()
  portions?: number;
}
