import { Moment } from "moment";
import { UserAffinity } from "@namedicinu/internal-types";

const Q = 0.5;

export default class UserQuizAffinity {
  public affinity: Record<string, number> = {};
  public lastRefresh: Moment;

  constructor(userAffinity: UserAffinity, lastRefresh: Moment) {
    this.affinity = userAffinity.affinity;
    this.lastRefresh = lastRefresh;
  }

  getQuestionAffinity(quid: string): number {
    return this.affinity[quid] || 0;
  }

  updateQuestionAffinity(quid: string, score: number): void {
    const norm = (x: number) => (-1 * Math.atan(x)) / (Math.PI / 2);
    this.affinity[quid] = (1 - Q) * this.getQuestionAffinity(quid) + Q * norm(score);
  }
}
