import * as yup from "yup";

import { IsYup } from "../../helpers/decorators";
import { yupRecord } from "../../schemas/record";

export class ClientAnalysisResults {
  @IsYup(yupRecord(yup.string().required(), yup.number().required()))
  ip: Record<string, number>;
  @IsYup(yupRecord(yup.string().required(), yup.number().required()))
  browser: Record<string, number>;
  @IsYup(yupRecord(yup.string().required(), yup.number().required()))
  browserVersion: Record<string, number>;
  @IsYup(yupRecord(yup.string().required(), yup.number().required()))
  os: Record<string, number>;
  @IsYup(yupRecord(yup.string().required(), yup.number().required()))
  osVersion: Record<string, number>;
  @IsYup(yupRecord(yup.string().required(), yup.number().required()))
  device: Record<string, number>;
  @IsYup(yupRecord(yup.string().required(), yup.number().required()))
  deviceType: Record<string, number>;
  @IsYup(yupRecord(yup.string().required(), yup.number().required()))
  deviceVendor: Record<string, number>;
}
