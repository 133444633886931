import * as yup from "yup";
import { type Moment } from "moment";
import { IsUUID, IsString, IsInt, IsOptional } from "class-validator";
import { Transform } from "class-transformer";

import { IsMoment, IsYup } from "../helpers/decorators";
import { transformMoment } from "../helpers/transforms";

export const materialDefinitionSchema = yup.object({
  title: yup.string().required(),
  text: yup
    .object({
      content: yup.string().required(),
    })
    .optional(),
  quiz: yup
    .object({
      linkId: yup.string().required(),
    })
    .optional(),
  file: yup
    .object({
      fileId: yup.string().required(),
      contentType: yup.string().required(),
      fileSize: yup.number().required(),
      fileName: yup.string().required(),
    })
    .optional(),
  protected: yup.boolean().required(),
  published: yup.boolean().optional().default(true),
});
export type MaterialDefinition = yup.InferType<typeof materialDefinitionSchema>;

export class StudyMaterialBase {
  @IsUUID()
  studyMaterialId: string;
  @IsInt()
  lectureNumber: number;
  @IsInt()
  order: number;
  @IsOptional()
  @IsYup(materialDefinitionSchema)
  definition: MaterialDefinition;
}

export class StudyMaterial extends StudyMaterialBase {
  @IsOptional()
  @Transform(transformMoment)
  @IsMoment()
  publishedAt?: Moment;
  @Transform(transformMoment)
  @IsMoment()
  createdAt: Moment;
  @IsString()
  createdBy: string;
  @Transform(transformMoment)
  @IsMoment()
  updatedAt: Moment;
  @IsString()
  updatedBy: string;
}
