import * as yup from "yup";
import { v5 as uuidv5, v4 as uuidv4 } from "uuid";

import { JsonObject } from "../types";

const guidNamespace = "41fd42b0-8118-4ca8-9568-ba377c215d10";
export const guidFrom = (obj: JsonObject): string => {
  return uuidv5(JSON.stringify(obj), guidNamespace);
};

export const guid = (): string => uuidv4();

export const tryValidate = <T>(schema: yup.Schema<T>, value: any): T | undefined => {
  try {
    return schema.validateSync(value);
  } catch {
    return undefined;
  }
};

export const sortByFields = <T>(...orders: Array<(o: T) => number | string>): ((a: T, b: T) => number) => {
  return (a, b) => {
    for (const order of orders) {
      const oa = order(a);
      const ob = order(b);
      if (typeof oa === "string" && typeof ob === "string") {
        const lc = oa.localeCompare(ob);
        if (lc !== 0) return lc;
      } else {
        if (oa < ob) return -1;
        if (oa > ob) return 1;
      }
    }
    return 0;
  };
};
