import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import {
  Container,
} from "reactstrap";
import { Outlet } from "react-router-dom";

import Breadcrumb from "../../../components/Breadcrumb";

const VideoManagerWrapper: FunctionComponent = () => {
  const intl = useIntl();

  return (
    <>
      <Breadcrumb
        parent={intl.formatMessage({id: "page.index.title", defaultMessage: "Namedicinu"})}
        title={intl.formatMessage({id: "module.video-manager.title", defaultMessage: "Video manager"})}
      />
      <Container fluid={true}>
        <Outlet />
      </Container>
    </>
  );
}

export default VideoManagerWrapper;