import { Fragment, FunctionComponent, ReactNode } from "react";
import { useIntl } from "react-intl";
import {
  Card, CardBody, Col,
  Container, Nav, NavItem, Row, NavLink, TabContent, TabPane,
} from "reactstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";

import Breadcrumb from "../../../components/Breadcrumb";
import { LectorProvider } from "../contexts/LectorContext";
import PageHead from "../../../components/PageHead";
import AppError from "../../../components/AppError";
import { handleError } from "../../../helpers/error";

export type ManageWrapperType = {
  tabs: Array<{
    id: string;
    name: ReactNode;
    element?: ReactNode;
  }>,
};

const UserWrapper: FunctionComponent<ManageWrapperType> = ({ tabs }) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const activeTab = tabs.find(
    (tab) => tab.id == "index" ? pathname == "/lector" : pathname.startsWith("/lector/"+tab.id)
  );

  const lectorStr = intl.formatMessage({id: "module.lector.title", defaultMessage: "Lector"});

  return (
    <>
      <PageHead>
        {lectorStr}
      </PageHead>
      <Breadcrumb
        parent={lectorStr}
        title={activeTab?.name || lectorStr}
      />
      <LectorProvider>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody className="p-3">
                  <Row>
                    <Col sm="2" className="tabs-responsive-side pt-3">
                      <Nav className="flex-column nav-pills border-tab-primary nav-left">
                        {tabs.map((tab) => (
                          tab.element ? (
                            <NavItem key={tab.id} style={{maxWidth: "100%"}}>
                              <NavLink
                                tag="button"
                                className={clsx({"active": activeTab?.id == tab.id}, "px-0")}
                                onClick={() => void navigate(
                                  tab.id == "index" ? `/lector` : `/lector/${tab.id}`, { replace: true }
                                )?.catch(handleError)}
                                style={{whiteSpace: "nowrap", maxWidth: "100%", overflow: "hidden"}}
                              >
                                {tab.name}
                              </NavLink>
                            </NavItem>
                          ) : (
                            <Fragment key={tab.id}>
                              {tab.name}
                            </Fragment>
                          )
                        ))}
                      </Nav>
                    </Col>
                    <Col sm="10">
                      <TabContent>
                        <TabPane>
                          <AppError>
                            <Outlet />
                          </AppError>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LectorProvider>
    </>
  );
}

export default UserWrapper;
