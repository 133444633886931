import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input, Card, Alert, Button } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { FacebookAuthProvider, GoogleAuthProvider, updatePassword, signInWithEmailLink, linkWithPopup, linkWithCredential, OAuthProvider, signInWithCredential } from "firebase/auth";
import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { Lock } from "react-feather";

import useApp from "../hooks/useApp";
import AsyncButton from "../components/AsyncButton";
import { errorMessage, handleError } from "../helpers/error";
import LoadingPlaceholder from "../components/LoadingPlaceholder";
import useLocale from "../hooks/useLocale";
import PageHead from "../components/PageHead";

const SetupPage: FunctionComponent = () => {
  const intl = useIntl();
  const { apiClient, auth, user } = useApp();
  const locale = useLocale();

  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const mode = useMemo(() => params.get("mode"), [params]);

  const [method, setMethod] = useState<"facebook"|"google"|"password">();
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [showError, setShowError] = useState<string>();

  useEffect(() => {
    if(mode == "start") {
      const token = params.get("token");
      const email = params.get("email");

      if(!token || !email) {
        setShowError(
          intl.formatMessage({id: "page.setup.missingTokenOrEmail", defaultMessage:"Missing token or email"})
        );
        return;
      }

      window.localStorage.setItem("setupEmail", email);
      apiClient.userSetupStart(token)
        .then((res) => {
          window.location.replace(res.link);
        })
        .catch(err => {
          setShowError(errorMessage(err));
        });

    } else if (mode == "signIn") {
      signInWithEmailLink(auth, window.localStorage.getItem("setupEmail")!, window.location.href)
      .then(() => window.localStorage.removeItem("setupEmail"))
      .catch(err => {
        setShowError(errorMessage(err));
      });

    } else {
      setShowError(
        intl.formatMessage({id: "page.setup.invalidMode", defaultMessage:"Invalid mode"})
      );
    }
  }, [mode, params]);

  const triggerHandleFacebook = useCallback(() => {
    const handleFacebook = async () => {
      try {
        setMethod("facebook");
        if(!auth.currentUser) throw new Error("Not authorized");
        const authUser = auth.currentUser;
        const provider = new FacebookAuthProvider();
        provider.setDefaultLanguage(locale);

        const result = await linkWithPopup(authUser, provider);
        const credential = FacebookAuthProvider.credentialFromResult(result);
        if(!credential) throw new Error("No credential");
        const linkResult = await linkWithCredential(authUser, credential)
        const linkCredential = OAuthProvider.credentialFromResult(linkResult);
        if(!linkCredential) throw new Error("No credential");
        await signInWithCredential(auth, linkCredential);

        setTimeout(() => {
          window.location.replace("/");
        }, 200);
      } catch (err) {
        setShowError(errorMessage(err));
      }
    };
    handleFacebook().catch(handleError);
  }, [auth, locale]);

  const triggerHandleGoogle = useCallback(() => {
    const handleGoogle = async () => {
      try {
        setMethod("google");
        if(!auth.currentUser) throw new Error("Not authorized");
        const authUser = auth.currentUser;
        const provider = new GoogleAuthProvider();
        provider.setDefaultLanguage(locale);

        const result = await linkWithPopup(authUser, provider);
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if(!credential) throw new Error("No credential");
        const linkResult = await linkWithCredential(authUser, credential)
        const linkCredential = OAuthProvider.credentialFromResult(linkResult);
        if(!linkCredential) throw new Error("No credential");
        await signInWithCredential(auth, linkCredential);

        setTimeout(() => {
          window.location.replace("/");
        }, 200);
      } catch (err) {
        setShowError(errorMessage(err));
      }
    };
    handleGoogle().catch(handleError);
  }, [auth, locale]);

  const handleSetPassword = async () => {
    try {
      if (password !== passwordRepeat) {
        setShowError(
          intl.formatMessage({id: "page.setup.passwordsDoNotMatch", defaultMessage:"Passwords do not match"})
        );
        return;
      }
      if(!auth.currentUser) throw new Error("Not authorized");

      await updatePassword(auth.currentUser, password);
      await auth.currentUser.getIdToken(true); // refresh token

      setTimeout(() => {
        window.location.replace("/");
      }, 200);
    } catch (err) {
      setShowError(errorMessage(err));
    }
  }

  return (
    <>
      <PageHead>
        {intl.formatMessage({ id: "page.setup.page-title", defaultMessage: "Setup" })}
      </PageHead>
      <div className="page-wrapper">
        <Container fluid={true} className="p-0">
          <div className="authentication-main m-0">
            <Row>
              <Col md="12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    {user ? (
                      <Card className="cont setup">
                        <Row className="mb-3">
                          <Col xs={12}>
                            <Row className="p-4">
                              <Col xs={12}>
                                <h2>
                                  <FormattedMessage id="page.setup.hello" defaultMessage="Hello {name}" values={{name: user.firstName}} />
                                </h2>
                              </Col>
                              <Col xs={12}>
                                <p>
                                  <FormattedMessage id="page.setup.welcome" defaultMessage="Welcome to the study course" />
                                </p>
                              </Col>
                              {showError ? (
                                <Col xs={12}>
                                  <Alert color="danger" className="outline">
                                    {showError}
                                  </Alert>
                                </Col>
                              ) : null}
                              {method === undefined ? (
                                <Col xs={12}>
                                  <h4>
                                    <FormattedMessage id="page.setup.pickLoginMethod" defaultMessage="Pick Login method" />
                                  </h4>
                                </Col>
                              ) : null}
                            </Row>
                          </Col>
                          <Col xs={12} className={method === undefined ? "social-login" : ""}>
                            <Row className={method === undefined ? "w-100" : "p-4"}>
                              {method == "facebook" ? (
                                <>
                                  <Col xs={4}>
                                    <Button color="social btn-facebook">
                                      <FontAwesomeIcon icon={faFacebookF} />
                                      <div className="mt-2">
                                        <FormattedMessage id="page.setup.facebook" defaultMessage="Facebook" />
                                      </div>
                                    </Button>
                                  </Col>
                                  <Col xs={8} className="d-flex flex-column justify-content-between">
                                    <div className="h4">
                                      <FormattedMessage id="page.setup.loginWithFacebook" defaultMessage="Login with Facebook" />
                                    </div>
                                    <p>
                                      <FormattedMessage id="page.setup.provider.text" defaultMessage="Please follow instructions in the opened window to complete the login" />
                                    </p>
                                    <div className="d-flex justify-content-end">
                                      <Button color="light" onClick={() => setMethod(undefined)}>
                                        <FormattedMessage id="page.setup.cancel" defaultMessage="Cancel" />
                                      </Button>
                                    </div>
                                  </Col>
                                </>
                              ) : method == "google" ? (
                                <>
                                  <Col xs={4}>
                                    <Button color="social btn-google">
                                      <FontAwesomeIcon icon={faGoogle} />
                                      <div className="mt-2">
                                        <FormattedMessage id="page.setup.google" defaultMessage="Google" />
                                      </div>
                                    </Button>
                                  </Col>
                                  <Col xs={8} className="d-flex flex-column justify-content-between">
                                    <div className="h4">
                                      <FormattedMessage id="page.setup.loginWithGoogle" defaultMessage="Login with Google" />
                                    </div>
                                    <p>
                                      <FormattedMessage id="page.setup.provider.text" defaultMessage="Please follow instructions in the opened window to complete the login" />
                                    </p>
                                    <div className="d-flex justify-content-end">
                                      <Button color="light" onClick={() => setMethod(undefined)}>
                                        <FormattedMessage id="page.setup.cancel" defaultMessage="Cancel" />
                                      </Button>
                                    </div>
                                  </Col>
                                </>
                              ) : method == "password" ? (
                                <>
                                  <Col xs={12}>
                                    <div className="h4">
                                      <FormattedMessage id="page.setup.loginWithEmailAndPassword" defaultMessage="Login using your Email & Password" />
                                    </div>
                                  </Col>
                                  <Col xs={12}>
                                    <Form className="theme-form">
                                      <input type="text" name="email" value={user.email} contentEditable={false} className="d-none" />
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="page.setup.password" defaultMessage="Password" />
                                        </Label>
                                        <Input
                                          className="btn-pill"
                                          type="password"
                                          value={password}
                                          onChange={(e) => setPassword(e.target.value)}
                                          required={true}
                                        />
                                      </FormGroup>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="page.setup.passwordRepeat" defaultMessage="Repeat password" />
                                        </Label>
                                        <Input
                                          className="btn-pill"
                                          type="password"
                                          value={passwordRepeat}
                                          onChange={(e) => setPasswordRepeat(e.target.value)}
                                          required={true}
                                        />
                                      </FormGroup>
                                      <div className="d-flex justify-content-end gap-3">
                                        <Button color="light" onClick={() => setMethod(undefined)} type="reset">
                                          <FormattedMessage id="page.setup.cancel" defaultMessage="Cancel" />
                                        </Button>
                                        <AsyncButton color="primary" onClick={handleSetPassword} type="submit" disabled={!password || !passwordRepeat}>
                                          <FormattedMessage id="page.setup.button" defaultMessage="Set new password" />
                                        </AsyncButton>
                                      </div>
                                    </Form>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col xs={4}>
                                    <Button color="social btn-facebook" onClick={triggerHandleFacebook}>
                                      <FontAwesomeIcon icon={faFacebookF} />
                                      <div className="mt-2">
                                        <FormattedMessage id="page.setup.facebook" defaultMessage="Facebook" />
                                      </div>
                                    </Button>
                                  </Col>
                                  <Col xs={4}>
                                    <Button color="social btn-google" onClick={triggerHandleGoogle}>
                                      <FontAwesomeIcon icon={faGoogle} />
                                      <div className="mt-2">
                                        <FormattedMessage id="page.setup.google" defaultMessage="Google" />
                                      </div>
                                    </Button>
                                  </Col>
                                  <Col xs={4}>
                                    <Button color="social btn-password" onClick={() => setMethod("password")}>
                                      <Lock />
                                      <div className="mt-2">
                                        <FormattedMessage id="page.setup.emailAndPassword" defaultMessage="Using Email & Password" />
                                      </div>
                                    </Button>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    ) : (
                      showError ? (
                        <Card>
                          <Row>
                            <Col xs={12}>
                              <Alert color="danger" className="outline">
                                {showError}
                              </Alert>
                            </Col>
                          </Row>
                        </Card>
                      ) : (
                        <LoadingPlaceholder />
                      )
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default SetupPage;
