import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

const Loader: FunctionComponent = () => {

  return (
    <div className="loader-wrapper">
      <div className="typewriter">
        <span>
          <FormattedMessage id="common.loading" defaultMessage="Loading..." />
        </span>
      </div>
    </div>
  );
};

export default Loader;
