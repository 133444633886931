
import { FunctionComponent } from "react";
import { FormattedMessage, type IntlFormatters } from "react-intl";

import { capitalize } from "../helpers/utils";

export const localizedTitle = (
  intl: IntlFormatters<string>, prefix: string, id: string, values?: Record<string, any>
): string => {
  /* eslint-disable formatjs/enforce-default-message */
  const res = intl.formatMessage({ id: `${prefix}.${id}`, defaultMessage: capitalize(id) }, values);
  if (typeof res !== "string") throw new Error(`Expected string, got ${typeof res}`);
  return res;
}

const LocalizedTitle: FunctionComponent<{
  prefix: string,
  id: string,
  values?: Record<string, any>,
}> = ({ prefix, id, values }) => {

  /* eslint-disable formatjs/enforce-default-message */
  return (
    <FormattedMessage
      id={`${prefix}.${id}`}
      defaultMessage={capitalize(id)}
      values={values}
    />
  );
}

export default LocalizedTitle;