import { IsArray, IsBoolean, IsEnum, IsInt, IsOptional, IsUUID } from "class-validator";
import { Transform } from "class-transformer";
import { IsMoment, locales, type LocaleType, transformMoment } from "@namedicinu/internal-types";

import { type WebEntryType } from "../types";

export abstract class WebEntry {
  abstract get type(): WebEntryType;

  @IsUUID()
  id: string;
  @IsBoolean()
  published: boolean;
  @IsOptional()
  @IsInt()
  order: number | null;
  @IsArray()
  @IsEnum(locales, { each: true })
  locales: LocaleType[];
  @Transform(transformMoment)
  @IsMoment()
  createdAt: moment.Moment;
  @Transform(transformMoment)
  @IsMoment()
  updatedAt: moment.Moment;
}
