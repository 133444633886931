import moment from "moment";
import { StudyMaterialLogAccess, User } from "@namedicinu/internal-types";

import ApiClient from "./apiClient";
import LocalDatabase from "./localDatabase";
import { StudyMaterialLogEntry } from "./types";

const CURRENT_LOG_VERSION = 2;

export default class StudyMaterialClient {
  constructor(
    private apiClient: ApiClient,
    private localDatabase: LocalDatabase,
  ) {}

  async startSession(user: User, localSessionId: string, studyGroupId: string, studyMaterialId: string): Promise<void> {
    await this.localDatabase.storeStudyMaterialSession({
      userId: user.userId,
      logVersion: CURRENT_LOG_VERSION,
      localSessionId,
      studyGroupId,
      studyMaterialId,
      timestamp: moment().toISOString(),
    });
  }

  async storeLogEntry(localSessionId: string, entry: StudyMaterialLogEntry): Promise<void> {
    await this.localDatabase.storeStudyMaterialLogEntry({ localSessionId, entry });
  }

  async storeLogEntries(localSessionId: string, entries: Array<StudyMaterialLogEntry>): Promise<void> {
    await this.localDatabase.storeStudyMaterialLogEntries(entries.map((entry) => ({ localSessionId, entry })));
  }

  async offloadLogs(user: User, currentLocalSession: string | undefined): Promise<void> {
    const sessions = await this.localDatabase.getStudyMaterialSessions(user.email);
    for (const session of sessions) {
      try {
        const entries = await this.localDatabase.getStudyMaterialLogEntries(session.localSessionId);
        if (entries.length > 0) {
          const time = moment
            .duration(
              entries.reduce((acc, entry) => acc + moment.duration(entry.entry.time).asMilliseconds(), 0),
              "milliseconds",
            )
            .toISOString();
          const access = entries.reduce(
            (acc: StudyMaterialLogAccess, entry) => (entry.entry.access === "download" ? "download" : acc),
            "view",
          );

          const studyMaterialLogRequest = {
            logVersion: session.logVersion || 0,
            studyMaterialSessionId: session.localSessionId,
            studyGroupId: session.studyGroupId,
            studyMaterialId: session.studyMaterialId,
            access,
            time,
            timestamp: session.timestamp,
            entries: entries.map((entry) => entry.entry),
          };

          await this.apiClient.submitStudyMaterialLog(studyMaterialLogRequest);
        }

        await this.localDatabase.clearStudyMaterialLog(
          session.localSessionId,
          session.localSessionId === currentLocalSession,
        );
      } catch (e) {
        console.error("Failed to offload logs", e);
      }
    }
  }
}
