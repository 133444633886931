import { FunctionComponent, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

import { modules } from "../modules";
import useApp from "../hooks/useApp";
import useWindowSize from "../hooks/useWindowSize";

type SidebarType = {
  sidebar: boolean;
  setSidebar: (sidebar: boolean) => void;
};

const Sidebar: FunctionComponent<SidebarType> = ({ sidebar, setSidebar }) => {
  const { pathname } = useLocation();
  const { userView } = useApp();

  const [width] = useWindowSize();
  const closeSidebarOnSmall = width <= 991 ? () => setSidebar(false) : () => {};

  const menuItems = modules.filter((module) => (
    module.allowed(userView)
  )).map((module) => ({
    title: module.name,
    icon: module.icon,
    // tag: <span className="badge rounded-pill badge-primary">New</span>,
    path: module.route.path,
  })) as Array<{
    title: string,
    icon: ReactNode,
    path: string,
    tag?: ReactNode,
  }>;

  return (
    <div className={clsx("iconsidebar-menu", {"iconbar-mainmenu-close": sidebar, "iconbar-second-close": !sidebar})}>
      <div className="sidebar custom-scrollbar">
        <ul className="iconMenu-bar custom-scrollbar">
          {menuItems.map((menuItem, i) => (
            <li className={clsx({
              "open": (
                pathname == menuItem.path ||
                pathname.startsWith(menuItem.path+"/")
              )
            })} key={i}>
              {menuItem.tag}
              <Link
                className="bar-icons"
                to={menuItem.path}
                onClick={closeSidebarOnSmall}
              >
                {menuItem.icon}
                <span>{menuItem.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
