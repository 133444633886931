import * as yup from "yup";

export class RecordSchema<
  A extends string | number | symbol,
  B,
  TType extends yup.Maybe<Record<A, B>> = Record<A, B> | undefined,
  TContext = yup.AnyObject,
  TDefault = undefined,
  TFlags extends yup.Flags = "",
> extends yup.Schema<TType, TContext, TDefault, TFlags> {
  constructor(a: yup.Schema<A, TContext, undefined, TFlags>, b: yup.Schema<B, TContext, undefined, TFlags>) {
    super({
      type: "record",
      check(value: any): value is NonNullable<TType> {
        if (typeof value !== "object") {
          return false;
        }
        for (const key of Object.keys(value)) {
          if (!a.isValidSync(key)) {
            return false;
          }
          if (!b.isValidSync(value[key])) {
            return false;
          }
        }
        return true;
      },
    });
  }

  override required(message?: yup.Message<any>): RecordSchema<A, B, NonNullable<TType>, TContext, TDefault, TFlags> {
    return super.required(message) as RecordSchema<A, B, NonNullable<TType>, TContext, TDefault, TFlags>;
  }

  override optional(): RecordSchema<A, B, TType, TContext, TDefault, TFlags> {
    return super.optional() as RecordSchema<A, B, TType, TContext, TDefault, TFlags>;
  }

  override nullable(): RecordSchema<A, B, TType | null, TContext, TDefault, TFlags> {
    return super.nullable() as RecordSchema<A, B, TType | null, TContext, TDefault, TFlags>;
  }
}

export const yupRecord = <A extends string | number | symbol, B>(a: yup.Schema<A>, b: yup.Schema<B>) => {
  return new RecordSchema(a, b);
};
