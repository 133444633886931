import moment, { Duration } from "moment";
import * as yup from "yup";

export class DurationSchema<
  TType extends yup.Maybe<Duration> = Duration | undefined,
  TContext = yup.AnyObject,
  TDefault = undefined,
  TFlags extends yup.Flags = "",
> extends yup.Schema<TType, TContext, TDefault, TFlags> {
  constructor() {
    super({
      type: "duration",
      check(value: any): value is NonNullable<TType> {
        return moment.isDuration(value);
      },
    });

    this.withMutation(() => {
      this.transform((value) => {
        if (moment.isDuration(value)) {
          return value;
        }
        if (typeof value == "string") {
          return moment.duration(value);
        }
        return undefined;
      });
    });
  }

  override required(message?: yup.Message<any>): DurationSchema<NonNullable<TType>, TContext, TDefault, TFlags> {
    return super.required(message) as DurationSchema<NonNullable<TType>, TContext, TDefault, TFlags>;
  }

  override optional(): DurationSchema<TType, TContext, TDefault, TFlags> {
    return super.optional() as DurationSchema<TType, TContext, TDefault, TFlags>;
  }
}

export const yupDuration = new DurationSchema();
