import { FunctionComponent, useState, Suspense, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Outlet, useLocation, useNavigate, useNavigationType, NavigationType } from "react-router-dom";
import clsx from "clsx";
import { logEvent } from "firebase/analytics";

import { modules } from "../modules";
import useApp from "../hooks/useApp";
import { handleError } from "../helpers/error";

import Header from "./Header";
import Loader from "./Loader";
import Sidebar from "./Sidebar";
import Unauthorized from "./Unauthorized";

const Layout: FunctionComponent = () => {
  const { user, userView, colorScheme, analytics } = useApp();
  const { pathname } = useLocation();
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(true);

  useEffect(() => {
    if (navigationType === NavigationType.Push) {
      window.scrollTo(0, 0);
    }

    const timeout = setTimeout(() => {
      logEvent(analytics, "page_view", {
        page_location: window.location.href,
        page_title: document.title,
      });
    }, 500);
    return () => clearTimeout(timeout);
  }, [pathname, navigationType]);

  useEffect(() => {
    if (user === null) {
      void navigate("/login")?.catch(handleError);
    }
  }, [user]);

  const module = modules.find((module) => (
    pathname == module.route.path ||
    pathname.startsWith((module.route.path || "") + "/")
  ));
  const authorizing = user === undefined;
  const authorized = module ? module.allowed(userView) : !!user;

  return (
    <>
      <div className={clsx("page-wrapper", colorScheme)}>
        <div className="page-body-wrapper">
          <Header sidebar={sidebar} setSidebar={setSidebar} />
          <Sidebar sidebar={!authorizing && sidebar} setSidebar={setSidebar} />
          <div className="page-body">
            {authorizing ? (
              <Loader />
            ) : (
              authorized ? (
                <Suspense fallback={<Loader />}>
                  <Outlet />
                </Suspense>
              ) : (
                <Unauthorized />
              )
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Layout;
