import { FunctionComponent, useState, useEffect, useCallback } from "react";
import { AlignCenter, User, Settings, LogOut, Maximize, MoreHorizontal, Moon, Sun } from "react-feather";
import { signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { toast } from "react-toastify";

import useApp from "../hooks/useApp";
import useUser from "../hooks/useUser";
import { handleError } from "../helpers/error";
import useWindowSize from "../hooks/useWindowSize";

import Notifications from "./Notifications";
import UserViewSelector from "./UserViewSelector";

type HeaderType = {
  sidebar: boolean;
  setSidebar: (sidebar: boolean) => void;
};

const Header: FunctionComponent<HeaderType> = ({ sidebar, setSidebar }) => {
  const { auth, storedColorScheme, colorScheme, nextColorScheme, userPic, userViews } = useApp();
  const user = useUser();
  const intl = useIntl();

  const [navmenu, setNavmenu] = useState(false);
  const [width] = useWindowSize();

  useEffect(() => {
    if (width <= 991) {
      setSidebar(false);
    } else {
      setSidebar(true);
    }
  }, [width]);

  const triggerFullscreen = useCallback(() => {
    const fullscreen = async () => {
      if (!document.fullscreenElement) {
        if(document?.documentElement?.requestFullscreen) {
          await document.documentElement.requestFullscreen();
        } else {
          toast.error("Fullscreen not supported");
        }
      } else {
        await document.exitFullscreen();
      }
    };
    fullscreen().catch(handleError);
  }, []);

  const triggerSignout = useCallback(() => {
    const signout = async () => {
      await signOut(auth);
    };
    signout().catch(handleError);
  }, [auth]);

  return (
    <div className="page-main-header">
      <div className="main-header-right">
        <div className="main-header-left text-center">
          <div className="logo-wrapper">
            <Link to={`/`}>
              {colorScheme == "light" ? (
                <img
                  src="/images/logo.png"
                  alt={intl.formatMessage({ id: "common.header.logo", defaultMessage: "Namedicinu logo" })}
                />
              ) : (
                <img
                  src="/images/logo-dark.png"
                  alt={intl.formatMessage({ id: "common.header.logo", defaultMessage: "Namedicinu logo" })}
                />
              )}
            </Link>
          </div>
        </div>
        <div className="mobile-sidebar">
          <div className="media-body text-end switch-sm">
            <label className="switch ms-3">
              <AlignCenter className="font-primary" onClick={() => setSidebar(!sidebar)} />
            </label>
          </div>
        </div>
        <div className="nav-right col pull-right right-menu">
          <ul className={clsx("nav-menus", {"open": navmenu})}>
            <li>
              {/* center */}
            </li>

            {userViews.length > 1 && (
              <li>
                <UserViewSelector />
              </li>
            )}

            <li>
              <button className="btn-text text-dark" onClick={triggerFullscreen}>
                <Maximize />
              </button>
            </li>

            <li>
              <button className="btn-text text-dark" onClick={nextColorScheme}>
                {storedColorScheme == undefined && (
                  colorScheme == "light" ? (
                    <Moon style={{opacity: 0.5}} />
                  ) : (
                    <Sun style={{opacity: 0.5}} />
                  )
                )}
                {storedColorScheme == "dark" && <Moon style={{color: "#7e37d8"}} />}
                {storedColorScheme == "light" && <Sun style={{color: "#ffc717"}} />}
              </button>
            </li>

            <Notifications />

            <li className="onhover-dropdown">
              <span className="media user-header">
                <img
                  className="img-fluid user-container"
                  src="/images/dashboard/user-container.png"
                  alt={intl.formatMessage({ id: "common.header.user.container", defaultMessage: "User" })}
                />
                {userPic ? (
                  <img
                    className="img-fluid user-pfp"
                    src={userPic}
                    alt={intl.formatMessage({ id: "common.header.user.profile", defaultMessage: "Profile Picture" })}
                  />
                ) : (
                  <img
                    className="img-fluid user-pfp"
                    src="/images/dashboard/user.png"
                    alt={intl.formatMessage({ id: "common.header.user.profile", defaultMessage: "Profile Picture" })}
                  />
                )}
              </span>
              <ul className="onhover-show-div profile-dropdown">
                <li className="card-g-primary">
                  <h5 className="f-w-600 mb-0">
                    {user.name}
                  </h5>
                </li>
                <li>
                  <Link to={`/user`}>
                    <User />
                    <FormattedMessage id="common.header.profile" defaultMessage="Profile" />
                  </Link>
                </li>
                <li>
                  <Link to={`/user/settings`}>
                    <Settings />
                    <FormattedMessage id="common.header.settings" defaultMessage="Settings" />
                  </Link>
                </li>
                <li onClick={triggerSignout}>
                  <LogOut />
                  <FormattedMessage id="common.header.logout" defaultMessage="Logout" />
                </li>
              </ul>
            </li>
          </ul>
          <div className="d-lg-none mobile-toggle pull-right" onClick={() => setNavmenu(!navmenu)}>
            <MoreHorizontal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
