import { FunctionComponent, useEffect, useState } from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Button, Col, Input, Nav, NavItem, NavLink, Popover, PopoverBody, PopoverHeader, Row } from "reactstrap";
import { Calendar, Clock } from "react-feather";
import { useNavigate } from "react-router-dom";

import { DateRange, PreddefinedDateRange, preddefinedDateRanges } from "../types";
import useApp from "../hooks/useApp";
import { handleError } from "../helpers/error";

import DateRangeOutlet from "./DateRangeOutlet";
import LocalizedTitle from "./LocalizedTitle";

const DateRangeControl: FunctionComponent = () => {
  const { dateRange, setDateRange, selectedDateRange } = useApp();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    if(search.has("date-range")) {
      const foundDateRange = search.get("date-range") as any;
      if(preddefinedDateRanges.includes(foundDateRange) && foundDateRange !== dateRange) {
        setDateRange(foundDateRange as PreddefinedDateRange);
      }
      const match = foundDateRange.match(/^(\d{4}-\d{2}-\d{2})\.\.(\d{4}-\d{2}-\d{2})$/);
      if(match) {
        const from = moment.utc(match[1]);
        const to = moment.utc(match[2]);
        if(from.isValid() && to.isValid()) {
          if(!selectedDateRange.from.isSame(from) || !selectedDateRange.to.isSame(to)) {
            setDateRange({from, to});
          }
        }
      }
    }
  }, [location.search]);

  const setDateRangeAndNavigate = (dateRange: DateRange, close?: boolean) => {
    const handle = async () => {
      const search = new URLSearchParams(location.search);
      if (dateRange === undefined) {
        search.delete("date-range");
      } else {
        if(typeof dateRange == "string") {
          search.set("date-range", dateRange);
        } else {
          search.set("date-range", `${dateRange.from.format("YYYY-MM-DD")}..${dateRange.to.format("YYYY-MM-DD")}`);
        }
      }
      await navigate(location.pathname + "?" + search.toString(), { replace: true });
      setDateRange(dateRange);

      if(close) {
        setOpen(false);
      }
    }
    handle().catch(handleError);
  }

  const changeDateRange = (from: string | undefined, to: string | undefined) => {
    const fromMoment = from ? moment.utc(from) : selectedDateRange.from;
    const toMoment = to ? moment.utc(to) : selectedDateRange.to;
    if(fromMoment.isValid() && toMoment.isValid()) {
      if(!selectedDateRange.from.isSame(fromMoment) || !selectedDateRange.to.isSame(toMoment)) {
        setDateRangeAndNavigate({from: fromMoment, to: toMoment});
      }
    }
  }

  return (
    <>
      <Button
        id="date-range-popover"
        type="button"
        onClick={() => setOpen(open => !open)}
        className="d-flex gap-2 align-items-center"
      >
        <Clock />
        <DateRangeOutlet />
      </Button>
      <Popover
        isOpen={open}
        toggle={() => setOpen(open => !open)}
        flip
        placement="bottom"
        target="date-range-popover"
      >
        <PopoverHeader>
          <FormattedMessage id="common.analytics.date-range.title" defaultMessage="Pick date range" />
        </PopoverHeader>
        <PopoverBody>
          <Nav vertical navbar className="mb-3">
            {preddefinedDateRanges.map((range) => (
              <NavItem key={range}>
                <NavLink onClick={() => setDateRangeAndNavigate(range, true)} className="d-flex gap-2 align-items-center">
                  <Calendar />
                  <span>
                    <LocalizedTitle prefix="common.analytics.date-range" id={range} />
                  </span>
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <Row>
            <Col xs={6}>
              <Input
                type="date"
                value={selectedDateRange.from.format("YYYY-MM-DD")}
                onChange={(e) => changeDateRange(e.target.value, undefined)}
              />
            </Col>
            <Col xs={6}>
              <Input
                type="date"
                value={selectedDateRange.to.format("YYYY-MM-DD")}
                onChange={(e) => changeDateRange(undefined, e.target.value)}
              />
            </Col>
          </Row>
        </PopoverBody>
      </Popover>
    </>
  );
}

export default DateRangeControl;
